import React from 'react'
import Title from 'components/title'
import ContentBlock from 'components/contentBlock'
import { trFunction } from 'utils/functions'
import Layout from 'components/layout'
import { graphql } from 'gatsby'

const Page = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  let page = data.thisPage
  let count = 1
  page.contentBlocks.forEach((block) => {
    if (block.template === 'accordion-process' && block.accordionItems) {
      block.accordionItems.forEach((item) => {
        item.pageIndex = count
        count++
      })
    }
  })
  return (
    <Layout>
      <div id="content" className="page-process">
        <Title
          title={page.title}
          subtitle={tr('BESPOKE')}
          subtitleLink="/bespoke"
        />

        <section className="page-content wrap">
          <div className="module text_content no-pad-top">
            <div className="wrap-text-large centered">
              {page.body && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.body.childMarkdownRemark.html,
                  }}
                />
              )}
            </div>
          </div>
        </section>
        {page.contentBlocks.map((block, i) => (
          <ContentBlock key={i} order={i} block={block} data={data} />
        ))}
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "process" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      contentBlocks {
        id
        slug
        template
        label
        title
        subtitle
        textPosition
        content {
          id
          childMarkdownRemark {
            html
          }
        }
        imagesFull {
          ...GatsbyImage
        }
        imagesFullMobile {
          ...GatsbyImage
        }
        imagesFullLink
        buttonLink
        imageSingle {
          ...GatsbyImage
        }
        accordionItems {
          ... on ContentfulAccordionItems {
            id
            title
            content {
              childMarkdownRemark {
                html
              }
            }
            image {
              ...GatsbyImage
            }
          }
        }
        video {
          wistiaCode
          optimizedFiles {
            file {
              url
              fileName
            }
          }
        }
        slides {
          images {
            ...GatsbyImage
          }
          caption
        }
      }
    }
  }
`
